<template>
    <div class="container-auth">
        <b-card class="p-3">
            <div class="text-center">
                <img class="w-50" src="@/assets/images/logo-horizontal.png" alt="">
            </div>
            <h3 class="mt-4 text-center">
                Welcome
            </h3>
            <AlertErrors />
            <div class="form-input mt-4">
                <span>User referral</span>
                <b-form-input v-model="form.user_parent" placeholder="Example" />
            </div>
            <div class="form-input mt-4">
                <span>Username</span>
                <b-form-input v-model="form.username" placeholder="Example" />
            </div>
            <div class="form-input mt-4">
                <span>Names</span>
                <b-form-input v-model="form.names" placeholder="John Due" />
            </div>
            <div class="form-input mt-4">
                <span>Pais</span>
                <country-select className="form-control" v-model="form.country" topCountry="US" />
            </div>
            <div class="form-input mt-4">
                <span>Number phone</span>
                
                <b-form-input type="number" v-model="form.phone" placeholder="124786874" />
            </div>
            <div class="form-input mt-4">
                <span>Email</span>
                <b-form-input type="email" v-model="form.email" placeholder="example@mail.com" />
            </div>
            <div class="form-input mt-4">
                <span>Password</span>
                <b-form-input type="password" placeholder="*******" v-model="form.password" />
            </div>
            <div class="form-input mt-4">
                <span>Repeat password</span>
                <b-form-input type="password" placeholder="*******" v-model="r_password" />
            </div>
            <div class="mt-4">
                <ButtonAction @click="onSubmit" title="Register" :loading="loading" block="true" lg="true" :disabled="form.email == null || form.password == null" />
            </div>
        </b-card>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
    props: ['username'],
    data () {
        return {
            form: {
                user_parent: null,
                names: null,
                username: null,
                country: null,
                phone: null,
                email: null,
                password: null
            },
            r_password: null,
            loading: false
        }
    },
    created () {
        if(this.username) {
            this.form.user_parent = this.username
        }
    },
    methods: {
        ...mapActions('interceptors', ['setError']),
        ...mapActions('auth', ['register']),
        onSubmit () {
            this.loading = true
            this.register(this.form).then(response => {
                this.loading = false
                this.$router.push({
                    name: 'Signin'
                })
                openNotification(response.message + '; Login')
            })
            this.loading = false
        }
    },
    watch: {
        r_password: function (val) {
            if(val != this.form.password) {
                this.setError(['Passwords not match'])
            } else {
                this.setError([])
            }
        }
    }
}
</script>
<style scoped>
    h3{
        text-align: left;
        font-size: 40px;
        line-height: 48px;
        color: white;
    }
    h6{
        text-align: left;
        color: white;
        font-size: 22px;
        font-weight: 200;
        line-height: 32px;
    }
    .form-input{
        text-align: left;
        color: white;
    }
</style>